<template>
  <div class="xwDetail">
    <div class="main">
      <div class="title">{{ xwObj.title }}</div>
      <div class="time">{{ xwObj.createTime }}</div>
      <div class="ql-snow">
        <div class="ql-editor" v-html="xwObj.text"></div>
      </div>
    </div>
  </div>
</template>


<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";

export default {
  name: "xwDetail",
  components: { headerNav, footerBtm },
  data() {
    return {
      xwObj: {},
    };
  },
  mounted() {
    // alert(this.$route.query.id);
    const id = this.$route.query.id;
    this.api.xwDetail(id).then((res) => {
      console.log(res.data.data);
      this.xwObj = res.data.data;
    });
  },
  methods: {},
};
</script>
<style scoped>
.main {
  max-width: 640px;
  padding: 50px 0;
  margin: 0px auto;
}
.title {
  font-size: 22px;
  margin-bottom: 14px;
}

.time {
  color: var(--weui-FG-2);
  font-size: 15px;
  margin-bottom: 20px;
}
.ql-editor {
  line-height: 24px;
}
@media screen and (max-width: 750px) {
  .main {
    width: 350px;
    padding: 50px 0;
    margin: 0px auto;
  }
}
</style>
